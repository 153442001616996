.header-wrapper {
    width: 100%;
    height: 40px;


    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid hsl(var(--border));
    background-color: hsl(var(--background));
    padding: 0 15px;
    box-sizing: border-box;
    z-index: 10;
}

.logo {
    background-color: hsl(20 14.3% 4.1%);
    padding: 6px 8px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
}

.logo img{
    height: 10px;
}
.nav-btn{
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: hsl(var(--foreground) / .8)
}

.nav img {
    margin-top: 4px;

}


.nav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.nav-btn ion-icon{
    font-size: 18px;
    color: hsl(var(--foreground));
    margin-top: 2px;
}

