.app-login {
    width: 100%;
    height: 100dvh;
    display: flex;
}

.login-section {
    width: 50%;
    height: 100%;
    position: relative;

    padding: 25px;
    box-sizing: border-box;
    display: flex;
}

.login-left{
    background-color: hsl(var(--primary));
    border-right: 1px solid hsl(var(--border));
    justify-content: space-between;
    flex-direction: column;
    color: hsl(var(--primary-foreground))
}

.login-left img{
    width: 100px;
}

.login-wrapper {
    background-color: hsl(var(--background));
    justify-content: center;
    align-items: center;
    box-shadow: -5px 0px 128px hsl(var(--background) / .6);
}



.login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 32px 48px;
    box-sizing: border-box;
    
}

.login h1{
    margin: 0;
    font-size: 28px;
    color: hsl(var(--foreground) / .8);
}

.login p{
    margin: 10px 0;
    font-size: 14px;
    font-weight: 300;
    color: hsl(var(--foreground) / .8);

}


.login-row{
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    box-sizing: border-box;
    width: 100%;
    gap: 4px;
}



.login-row label {
    color: hsl(var(--foreground) / .8);
    font-size: 12px;
    font-weight: 500;
}

.login-row input{
    outline: none;
    border: 1px solid hsl(var(--border));
    
    background-color: hsl(var(--background));
    color: hsl(var(--foreground) / .9);
    font-family: inherit;

    border-radius: 4px;
    padding: 4px 8px;
    box-sizing: border-box;
}

.login-row button {
    outline: none;
    border: 1px solid transparent;
    background: radial-gradient(circle, hsl(var(--primary) / 0.5), hsl(var(--primary) / 0.9));
    transition: all 0.4s ease;
    color: hsl(var(--primary-foreground));
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
    box-sizing: border-box;
    width: 100%;
    font-family: inherit;
    cursor: pointer;
    box-shadow: 0 8px 20px hsl(var(--primary) / 0.1);
}

.login-row button:hover {
    border-radius: 6px;
}



@media screen and (max-width:600px) {
    .app-login{
        flex-direction: column;
    }

    .login-section {
        width: 100%;
        height: 50%;
    }

    .login-left{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 40px;
    }

    .login-left span{
        font-size: 12px;
        display: none;
    }

    .login-wrapper{
        height: 100%;
        padding: 10px;
    }

    .login {
        padding: 10px;
    }

    .login p {
        font-size: 12px;
    }
}