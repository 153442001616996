@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 129 33% 56%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142.1 76.2% 36.3%;
    --radius: 0.5rem;
  }

  .dark-theme {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 129 33% 56%;
    --primary-foreground: 144.9 80.4% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142.4 71.8% 29.2%;
  }
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: -0.5px;
  overflow-x: hidden;
  background-color: hsl(var(--background));
}


/* Width of the scrollbar */
body::-webkit-scrollbar {
  width: 10px;
}

/* Color of the scrollbar track */
body::-webkit-scrollbar-track {
  background: hsl(var(--background)); /* Change to desired background color */
}

/* Color of the scrollbar thumb/handle */
body::-webkit-scrollbar-thumb {
  background: hsl(var(--primary) / .5); /* Change to desired thumb color */
}

/* Rounded corners for the scrollbar thumb */
body::-webkit-scrollbar-thumb:hover {
  background: hsl(var(--primary) / .7); /* Change to desired thumb color */
}


button, input, textarea {
  letter-spacing: -0.5px;
}

.btn{
  outline: none;
  border: 1px solid hsl(var(--border));
  border-radius: var(--radius);

  background-color: hsl(var(--background));

  color: hsl(var(--foreground));
  font-family: inherit;
  font-weight: 600;


  padding: 4px 32px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color .4s ease;

}

.btn:hover{
  background-color: hsl(var(--primary) / .1);
}

.btn-primary{
  outline: none;
  border: 1px solid hsl(var(--border));
  border-radius: var(--radius);

  background-color: hsl(var(--primary));

  color: hsl(var(--primary-foreground));
  font-family: inherit;
  font-weight: 600;


  padding: 4px 32px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color .4s ease;

}

.btn-primary:hover{
  background-color: hsl(var(--primary) / .1);
}


.title{
  margin: 0;
  color: hsl(var(--foreground));
}