.admin-wrapper {
    /* height: calc(100dvh - 41px); */
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: hsl(var(--background));

}

.admin {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;

}



.admin-section {
    width: 33.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.admin-section:nth-child(1){
    border-right: 0.5px solid hsl(var(--border));
}


.admin-section:nth-child(2){
    border-left: 0.5px solid hsl(var(--border));
    border-right: 0.5px solid hsl(var(--border));

}

.admin-section:nth-child(3){
    border-left: 0.5px solid hsl(var(--border));
}

@media screen and (max-width: 600px) {
    .admin-wrapper {
        width: 100%;
        /* min-height: calc(100dvh - 1px); */

    }

    .admin {
        flex-direction: column;
        width: 100%;
        /* min-height: calc(100% - 40px); */

    }

    .admin-section {
        width: 100%;
    }
}


.bx {
    padding: 8px;
    box-sizing: border-box;
    border-bottom: 1px solid hsl(var(--border));
}

.bx h1 {
    margin: 0;
    font-weight: 600;
    color: hsl(var(--foreground));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.bx h1 ion-icon{
    margin-top: 4px;
    cursor: pointer;
}


.list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

@media screen and (max-width: 600px) {
    .list {
        height: 200px;
        overflow: hidden;
        overflow-y: scroll;
    }

    .list::-webkit-scrollbar {
        width: 8px;
    }

    .list::-webkit-scrollbar-track {
        background: hsl(var(--background));
    }

    .list::-webkit-scrollbar-thumb {
        background: hsl(var(--primary) / .5);
    }

    .list::-webkit-scrollbar-thumb:hover {
        background: hsl(var(--primary) / .7);
    }

}


.list-item {
    width: 100%;
    border: 1px solid hsl(var(--border));
    padding: 6px;
    box-sizing: border-box;
    border-radius: 0px;
    color: hsl(var(--foreground));
    cursor: pointer;
    display: flex;
    flex-direction: column;
    transition: all .4s ease;
}

.edit-item {
    gap: 4px;
}

.list-item:hover,
.list-item:focus,
.list-item:active {
    border: 1px solid hsl(var(--primary) /.8)
}

.item-head {
    display: flex;
    justify-content: space-between;
}

.item-head span {
    font-weight: 400;
    font-size: 12px;
    color: hsl(var(--foreground) / .7);
}

.head-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.head-icons span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid hsl(var(--border));
    padding: 1px 10px;
    box-sizing: border-box;
    font-size: 10px;
}

.head-icons ion-icon{
    font-size: 10px;
}

.item-foot {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.item-foot p {
    margin: 0;
}



.item-foot img{
    width: 100%;
}

.edit-foot {
    gap: 4px;
}

.item-foot span:nth-child(1) {
    font-weight: 600;
    font-size: 12px;
    color: hsl(var(--foreground) / .9);
}

.item-foot span:nth-child(2) {
    font-weight: 500;
    font-size: 12px;
    color: hsl(var(--foreground) / .8);
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.bx button,
.bx input,
.bx textarea {
    width: 100%;
    outline: none;
    border: 1px solid hsl(var(--border));
    font-family: inherit;
    padding: 4px 8px;
    box-sizing: border-box;
    color: hsl(var(--foreground));
}

.bx input,
.bx textarea {
    background-color: hsl(var(--background));
}

.bx textarea {
    resize: vertical;
    min-height: 100px;
    max-height: 500px;
}


.bx button {
    cursor: pointer;
    transition: all 0.4s ease;
    background-color: hsl(var(--primary) /.2);

}

.bx button:hover {
    background-color: hsl(var(--primary) /.6);
}

.active {
    background-color: hsl(var(--primary) /.4) !important;
    color: hsl(var(--foreground) / .9)
}

.delete {
    background-color: hsl(var(--destructive) /.2) !important;
    color: hsl(var(--foreground) / .9);
    transition: all .4s ease;
}

.delete:hover {
    background-color: hsl(var(--destructive) /.6) !important;
}

.edit input,
.edit textarea {
    border: none;
    border-radius: 0;
    padding: 0px;
}

.edit input {
    border-bottom: 1px solid hsl(var(--border));
    padding: 6px 0;
}

.ql-picker-options{
    background-color: hsl(var(--background)) !important;
}